import { TransformName, TransformType } from "../types/transform";
import { PlotType } from "./plot_requests/plot_request_interfaces";
import { PlotSettings } from "./plot_settings/plot_settings_interfaces";

export const defaultTransformValues: Record<TransformName, TransformType> = {
  [TransformName.INVESTMENT_PERFORMANCE]: {
    transform: TransformName.INVESTMENT_PERFORMANCE,
    start_val: 100,
  },
  [TransformName.ROLLING_VOL]: {
    transform: TransformName.ROLLING_VOL,
    window: 20,
  },
  [TransformName.ROLLING_RET]: {
    transform: TransformName.ROLLING_RET,
    window: 20,
  },
  [TransformName.ROLLING_DD]: {
    transform: TransformName.ROLLING_DD,
  },
  [TransformName.ROLLING_CORREL]: {
    transform: TransformName.ROLLING_CORREL,
    correl_column: "",
    window: 252,
  },
  [TransformName.RETURNS]: {
    transform: TransformName.RETURNS,
  },
};

export const transformSpecificSettings: Record<
  PlotType,
  Partial<Record<TransformName, Partial<PlotSettings>>>
> = {
  [PlotType.LinePlot]: {
    [TransformName.INVESTMENT_PERFORMANCE]: {
      y_axis_tick_format: "$1,.0f",
      place_horizontal_line_at: 100,
      scale_y_log2: true,
    },
    [TransformName.ROLLING_VOL]: {
      y_axis_tick_format: "^1,.0%",
    },
    [TransformName.ROLLING_RET]: {
      y_axis_tick_format: "^1,.0%",
    },
    [TransformName.ROLLING_DD]: {
      y_axis_tick_format: "^1,.0%",
    },
    [TransformName.ROLLING_CORREL]: {
      y_axis_tick_format: "^1,.0%",
    },
  },
  [PlotType.BarPlot]: {
    [TransformName.RETURNS]: {
      y_axis_tick_format: "^1,.0%",
      annotations_format: "^1,.0%",
      show_annotations: true,
    },
  },
  [PlotType.CorrelPlot]: {
    [TransformName.RETURNS]: {},
  },
};
