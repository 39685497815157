// Enum for transform names
export enum TransformName {
  INVESTMENT_PERFORMANCE = "Investment Performance",
  ROLLING_VOL = "Rolling Volatility",
  ROLLING_DD = "Max Drawdown",
  ROLLING_RET = "Rolling Returns",
  ROLLING_CORREL = "Rolling Correlation",
  RETURNS = "Returns",
}

// Base interface for a transform
interface BaseTransform {
  transform: TransformName;
}

// Specific interfaces for each transform type
export interface InvestmentPerformance extends BaseTransform {
  transform: TransformName.INVESTMENT_PERFORMANCE;
  start_val: number;
}

export interface RollingVol extends BaseTransform {
  transform: TransformName.ROLLING_VOL;
  window: number;
}

export interface RollingRet extends BaseTransform {
  transform: TransformName.ROLLING_RET;
  window: number;
}

export interface RollingDD extends BaseTransform {
  transform: TransformName.ROLLING_DD;
}

export interface RollingCorrel extends BaseTransform {
  transform: TransformName.ROLLING_CORREL;
  correl_column: string;
  window: number;
}

export interface Returns extends BaseTransform {
  transform: TransformName.RETURNS;
}

// Union type for all transform types
export type TransformType =
  | Returns
  | InvestmentPerformance
  | RollingVol
  | RollingRet
  | RollingDD
  | RollingCorrel;
