import React from "react";
import {
  Typography,
  Box,
  Divider,
  Switch,
  Stack,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TableSettings from "./subcomponents/TableSettings";
import PerformanceMetrics from "./subcomponents/PerformanceMetrics";
import ExcessReturns from "./subcomponents/ExcessReturns";
import { BaseTheme } from "../../../../types/plot_theme/plot_theme_interfaces";
import { Portfolio } from "../../../../types/data_config/data_config_interfaces";
import { PerformanceTableRequest } from "../../../../types/plot_requests/performance_table";

interface PerformanceTablePanelProps {
  isPerformanceTableEnabled: boolean;
  setPerformanceTableEnabled: (enabled: boolean) => void;
  performanceTableConfig: PerformanceTableRequest;
  theme: BaseTheme;
  portfolios: Portfolio[];
  onChange: (updatedPerformanceTable: Partial<PerformanceTableRequest>) => void;
}

const PerformanceTablePanel: React.FC<PerformanceTablePanelProps> = ({
  isPerformanceTableEnabled,
  setPerformanceTableEnabled,
  performanceTableConfig,
  portfolios,
  theme,
  onChange,
}) => {
  if (isPerformanceTableEnabled) {
    return (
      <Box height="100%" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 0.5 }}
        >
          <Typography
            variant="button"
            sx={{
              fontWeight: 800,
              fontSize: 13,
              width: "fit-content",
              color: "#c6c6c6",
            }}
          >
            Performance Table
          </Typography>
          <Box display="flex">
            <Switch
              checked={isPerformanceTableEnabled}
              onChange={(event) =>
                setPerformanceTableEnabled(event.target.checked)
              }
              size="small"
            />
          </Box>
        </Box>
        <Divider />
        <Box padding="5px 20px 0 20px">
          <PerformanceMetrics
            onChange={(selectedMetrics) =>
              onChange({ metrics: selectedMetrics })
            }
            metrics={performanceTableConfig.metrics}
          />
        </Box>
        <Divider />
        <Box padding="20px">
          <TableSettings
            onChange={(newSettings) => onChange({ settings: newSettings })}
            theme={theme}
            settings={performanceTableConfig.settings}
          />
        </Box>
        <Divider />
        <Stack paddingTop="20px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="button"
              sx={{
                fontWeight: 800,
                fontSize: 13,
                width: "fit-content",
                color: "#c6c6c6",
                pl: 0.5,
              }}
            >
              Subtract Risk Free Rate
            </Typography>
            <Tooltip title="Select for any series where the risk free rate should be subtracted when computing the Sharpe and Sortino ratios.">
              <IconButton size="small">
                <HelpOutlineIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider />
          <ExcessReturns
            onChange={(newExcessReturns) => {
              const updatedMetrics = performanceTableConfig.metrics.map(
                (metric) => {
                  if ("use_excess_returns" in metric) {
                    return { ...metric, use_excess_returns: newExcessReturns };
                  }
                  return metric;
                },
              );
              onChange({ metrics: updatedMetrics });
            }}
            options={portfolios.map((portfolio) => portfolio.name)}
            useExcessReturns={portfolios
              .filter((portfolio) =>
                performanceTableConfig.metrics.some(
                  (metric) =>
                    "use_excess_returns" in metric &&
                    metric.use_excess_returns?.includes(portfolio.name),
                ),
              )
              .map((portfolio) => portfolio.name)}
          />
        </Stack>
      </Box>
    );
  } else {
    return (
      <Box height="100%" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 0.5 }}
        >
          <Typography
            variant="button"
            sx={{
              fontWeight: 800,
              fontSize: 13,
              width: "fit-content",
              color: "#c6c6c6",
            }}
          >
            Performance Table
          </Typography>
          <Box display="flex">
            <Button
              variant="contained"
              size="small"
              onClick={() => setPerformanceTableEnabled(true)}
            >
              Enable
            </Button>
          </Box>
        </Box>
        <Divider />
        <Box padding="20px">
          <Typography variant="body2" color="text.secondary">
            Enable the Performance Table to configure its settings.
          </Typography>
        </Box>
      </Box>
    );
  }
};

export default PerformanceTablePanel;
