import { PlotType } from "../plot_requests/plot_request_interfaces";
import {
  BaseSettings,
  LinePlotSettings,
  CorrelPlotSettings,
  BaseMatplotSettings,
  HorizontalLineType,
  BarPlotSettings,
} from "./plot_settings_interfaces";

export const defaultBaseSettings: BaseSettings = {
  title: null,
  title_fontsize: 22,
  title_justify: "center",

  subtitle: null,
  subtitle_fontsize: 16,
  subtitle_justify: "center",

  caption:
    "For illustrative purposes only. Past performance is not indicative of future results.",
  caption_fontsize: 15,
  caption_justify: "left",

  x_label: null,
  x_label_fontsize: 12,
  x_axis_tick_angle: "0",
  x_axis_tick_format: null,
  x_axis_tick_fontsize: 15,

  y_label: null,
  y_label_fontsize: 12,
  y_axis_tick_angle: "0",
  y_axis_tick_format: null,
  y_axis_tick_fontsize: 15,

  axis_thickness: 1,
  axis_color: "#000000",

  figsize: [1200, 500],
  panel_border: true,
  show_panel_grid: false,
  canvas_color: undefined,

  show_legend: true,
  legend_position: "top",
  legend_title: null,
  legend_fontsize: 15,
};

const defaultBaseMatplotSettings: BaseMatplotSettings = {
  ...defaultBaseSettings,
  dpi: 150,
};

export const defaultBarPlotSettings: BarPlotSettings = {
  ...defaultBaseSettings,
  show_annotations: true,
};

export const defaultLinePlotSettings: LinePlotSettings = {
  ...defaultBaseSettings,
  scale_y_log2: false,
  show_date_range: false,
  place_horizontal_line_at: undefined,
  horizontal_line_type: HorizontalLineType.SOLID,
  horizontal_line_color: "#000000",
  horizontal_line_size: 1,
};

export const defaultCorrelPlotSettings: CorrelPlotSettings = {
  ...defaultBaseMatplotSettings,
  show_annotation: true,
  annotation_fmt: ".2f",
  annotation_fontsize: 10,
  x_axis_tick_fontsize: 10,
  y_axis_tick_fontsize: 10,
  annotation_decimals: 2,
  annotation_color: "white",
  square: true,
  linewidths: 0,
  mask_upper: true,
  show_legend: false,
  show_panel_grid: false,
  caption_fontsize: 10,
  x_axis_tick_angle: "45",
  panel_border: false,
};

export type BasePlotSettings =
  | BarPlotSettings
  | LinePlotSettings
  | CorrelPlotSettings;

export const defaultPlotSettings: Record<PlotType, BasePlotSettings> = {
  [PlotType.BarPlot]: defaultBarPlotSettings,
  [PlotType.LinePlot]: defaultBarPlotSettings,
  [PlotType.CorrelPlot]: defaultBarPlotSettings,
};
