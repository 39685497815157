import { TransformName } from "../transform";
import { defaultTransformValues } from "../transformDefaults";
import { DataConfig, Periodicity } from "./data_config_interfaces";

export const defaultDataConfig: DataConfig = {
  start_date: "1990-01-02",
  end_date: new Date(Date.now() - 86400000).toISOString().split("T")[0],
  portfolios: [],
  transform: defaultTransformValues[TransformName.INVESTMENT_PERFORMANCE],
  periodicity: Periodicity.DAYS,
};
