import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Divider,
  IconButton,
  Menu,
} from "@mui/material";
import {
  Settings as SettingsIcon,
  LineAxis as LineAxisIcon,
  FormatPaint as FormatPaintIcon,
  BackupTable as BackupTableIcon,
  Add as AddIcon,
  Refresh as RefreshIcon,
  Transform as TransformIcon,
  FileDownload as ExportIcon,
  Code as CodeIcon,
  BarChart as BarChartIcon,
  CloudCircle as CloudCircleIcon,
  MoreVert as MoreVertIcon,
  BugReport as BugReportIcon,
  Lightbulb as LightbulbIcon,
} from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { usePlotContext } from "../contexts/PlotContext";
import { isValidPlotConfig } from "../utils/isValidPlotConfig";
import { getPlot } from "../utils/requestUtils";
import { PLOT_TYPE_INFO } from "../types/plot_requests/plot_request_info";
import {
  PlotRequest,
  PlotType,
} from "../types/plot_requests/plot_request_interfaces";
import {
  QuickAddElement,
  QuickActionsDialog,
  QuickAction,
  QuickExport,
} from "../components/quick_actions";
import {
  SettingsPanel,
  DataConfigPanel,
  ThemePanel,
  PerformanceTablePanel,
  TransformPanel,
  FilesPanel,
} from "../components/panel_drawer/panels";
import PanelDrawer, { Panel } from "../components/panel_drawer/PanelDrawer";
import RamLogo from "../../../common/components/RAMLogo";
import { RAMFormControl } from "../../../resources/CustomInputs";
import { useUserReport } from "../../../common/contexts/UserReportContext";
import useElementSize from "../utils/useElementSize";
import { processHTML } from "../utils/processHTML";
import DisclaimerSection from "../../../common/components/DisclaimerSection";
import EnterDate from "../components/panel_drawer/panels/SettingsPanel/setting_inputs/DateRangeInput";
import LargePopup from "../../../common/showcase/v1.1/Showcase";
declare global {
  interface Window {
    LetsPlot: any;
    plotSpec: any;
  }
}

const PlotUI: React.FC = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const [isPerformanceTableEnabled, setPerformanceTableEnabled] =
    useState<boolean>(true);
  const plotRef = useRef<HTMLDivElement>(null);
  const perfRef = useRef<HTMLDivElement>(null);
  const devMode = process.env.REACT_APP_DEV_MODE === "true";
  const { plot, performanceTable } = usePlotContext();
  const { getAccessTokenSilently } = useAuth0();
  const { width, height } = useElementSize(plotRef, 100);
  const { openErrorPrompt, openBugReport, openFeatureRequest } =
    useUserReport();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const firstVisitFlag = localStorage.getItem("firstVisitFlag");
    if (!firstVisitFlag) {
      setIsDialogOpen(true);
      localStorage.setItem("firstVisitFlag", "true");
    }
  }, []);

  const loadPlot = async () => {
    if (isValidPlotConfig(plot.config)) {
      // scale plot to current screen dimensions
      const scaledPlot = {
        ...plot.config,
        settings: {
          ...plot.config.settings,
          figsize: [width, height],
        },
      } as PlotRequest;

      // request plot
      const token = await getAccessTokenSilently();
      const response = await getPlot(scaledPlot, token);
      if (response.isSuccess) {
        const htmlContent = await response.body.text();
        processHTML(htmlContent, plotRef);
        if (plotRef.current) {
          plotRef.current.innerHTML = htmlContent;
        }
      } else {
        // handle errors
        const warning = "An error occurred while creating the plot.";
        const slackMessage = "The Plotting App Failed to load the plot";
        openErrorPrompt(warning, slackMessage);
      }
    }
  };

  const loadPerformanceTable = async () => {
    if (isPerformanceTableEnabled) {
      if (isValidPlotConfig(plot.config) && performanceTable.config) {
        // add data and theme from plot config to request
        const performanceTableForm = {
          ...performanceTable.config,
          data_config: plot.config.data_config,
          theme: plot.config.theme,
        };

        // request table
        const response = await getPlot(
          performanceTableForm,
          await getAccessTokenSilently(),
        );
        if (response.isSuccess) {
          const htmlContent = await response.body.text();
          if (perfRef.current) {
            perfRef.current.innerHTML = htmlContent;
          }
        } else {
          // handle errors
          const warning = "An error occurred while creating the plot.";
          const slackMessage = `The Plotting App Failed to load performance table`;
          openErrorPrompt(warning, slackMessage);
        }
      }
    } else {
      if (perfRef.current) {
        perfRef.current.innerHTML = "";
      }
    }
  };

  // load plot if config changes
  useEffect(() => {
    loadPerformanceTable();
    loadPlot();
  }, [
    plot.config,
    width,
    height,
    isPerformanceTableEnabled,
    performanceTable.config,
  ]);

  // Menu handlers - (report bug/request feature)
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      {/* New Featuer Showcase */}
      <LargePopup open={isDialogOpen} onClose={handleCloseDialog} />

      {/* Top Bar */}
      <Box
        height="50px"
        display="flex"
        flexDirection="column"
        sx={{ backgroundColor: "background.paper" }}
      >
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1 }}
        >
          <Box width="200px" display="flex" gap={2}>
            <RamLogo width="100px" />
            <Box sx={{ minWidth: 100, ml: 2 }}>
              <RAMFormControl fullWidth size="small">
                <InputLabel>Plot Type</InputLabel>
                <Select<PlotType>
                  value={plot.config.plot_type}
                  label="Plot Type"
                  onChange={(event) => {
                    plot.setType(event.target.value as PlotType);
                  }}
                >
                  {(
                    Object.entries(PLOT_TYPE_INFO) as [
                      PlotType,
                      { name: string },
                    ][]
                  ).map(([plotType, info]) => (
                    <MenuItem key={plotType} value={plotType}>
                      {info.name}
                    </MenuItem>
                  ))}
                </Select>
              </RAMFormControl>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box width="175px">
              <EnterDate variant="text" />
            </Box>
          </Box>
          <Box display="flex">
            <IconButton
              onClick={handleMenuOpen}
              size="small"
              aria-controls={menuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => openFeatureRequest("PLOTTING APP")}>
                <LightbulbIcon sx={{ mr: 1 }} />
                Request Feature
              </MenuItem>
              <MenuItem onClick={() => openBugReport("PLOTTING APP")}>
                <BugReportIcon sx={{ mr: 1 }} />
                Report Bug
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Divider />
      </Box>

      <Box
        display="flex"
        justifyContent="flex-start"
        height="calc(100% - 50px)"
      >
        <PanelDrawer>
          <Panel name="Settings" icon={SettingsIcon}>
            <SettingsPanel />
          </Panel>
          <Panel name="Plot Elements" icon={LineAxisIcon}>
            <DataConfigPanel
              dataConfig={plot.config.data_config}
              onChange={(value) => plot.update({ data_config: value })}
            />
          </Panel>

          <Panel name="Transform Options" icon={TransformIcon}>
            <TransformPanel />
          </Panel>

          <Panel name="Performance Table" icon={BackupTableIcon}>
            <PerformanceTablePanel
              isPerformanceTableEnabled={isPerformanceTableEnabled}
              setPerformanceTableEnabled={setPerformanceTableEnabled}
              performanceTableConfig={performanceTable.config}
              portfolios={plot.config.data_config.portfolios}
              theme={plot.config.theme}
              onChange={(value) => performanceTable.update(value)}
            />
          </Panel>

          <Panel name="Theme" icon={FormatPaintIcon}>
            <ThemePanel
              plotTheme={plot.config.theme}
              onChange={(partialTheme) => {
                plot.update({
                  theme: {
                    ...plot.config.theme,
                    ...partialTheme,
                  },
                });
              }}
            />
          </Panel>
          <Panel name="Files" icon={CloudCircleIcon}>
            <FilesPanel />
          </Panel>
        </PanelDrawer>

        {/* Main Display Area */}
        <Box
          height="100%"
          display="flex"
          justifyContent="flex-end"
          flexDirection="column"
          alignContent="center"
          sx={{ flexGrow: 1, overflow: "hidden", backgroundColor: "white" }}
        >
          {/* Plot Frame  */}
          <Box
            ref={plotRef}
            sx={{
              flexGrow: 1,
              width:
                plot.config.plot_type === PlotType.CorrelPlot
                  ? "fit-content"
                  : "calc(100% - 30px)",
              height: "100%",
              aspectRatio:
                plot.config.plot_type === PlotType.CorrelPlot
                  ? "1 / 1"
                  : "auto",
              color: "black",
              margin:
                plot.config.plot_type === PlotType.CorrelPlot
                  ? "auto"
                  : "0 15px",
              overflow: "hidden",
            }}
          />
          {/* Performance Table Frame */}
          {isPerformanceTableEnabled && (
            <Box
              ref={perfRef}
              sx={{
                width: "calc(100% - 30px)",
                color: "black",
                margin: "0 15px",
              }}
            />
          )}

          {/* Bottom Disclaimer */}
          <Box
            sx={{
              position: "relative",
              height: "auto",
              bgcolor: "background.paper",
              px: 1,
              py: 0.5,
            }}
          >
            <DisclaimerSection variant="dialog" />
          </Box>

          {/* Quick Actions Shortcuts */}
          <QuickActionsDialog
            position={{ vertical: "top", horizontal: "left" }}
            direction="right"
            tooltipPlacement="top"
            spacing={24}
            size="small"
          >
            <QuickAction
              name="Refresh"
              icon={<RefreshIcon />}
              onClick={() => {
                loadPlot();
                loadPerformanceTable();
              }}
            />

            <QuickAction name="Add Element" icon={<AddIcon />}>
              <QuickAddElement
                onChange={(newPortfolios) =>
                  plot.update({
                    data_config: {
                      ...plot.config.data_config,
                      portfolios: [
                        ...plot.config.data_config.portfolios,
                        ...newPortfolios,
                      ],
                    },
                  })
                }
              />
            </QuickAction>

            <QuickAction
              name="Export"
              icon={<ExportIcon />}
              disabled={!isValidPlotConfig(plot.config)}
            >
              <QuickExport plotRequest={plot.config} />
            </QuickAction>

            {devMode && (
              <QuickAction
                name="Log Configs"
                icon={<CodeIcon />}
                onClick={() => console.log("plot config", plot.config)}
              />
            )}
          </QuickActionsDialog>
        </Box>
      </Box>
    </>
  );
};

export default PlotUI;
