import { DataConfig, Periodicity } from "../data_config/data_config_interfaces";
import { defaultDataConfig } from "../data_config/default_data_config";

export interface PerformanceTableSettings {
  row_height?: number;
  header_color?: string;
  table_width?: number;
  font_family?: string;
  font_size: number;
  alternate_row_color: string;
  cell_padding: number;
  first_column_align: string;
  other_columns_align: string;
}

export enum MetricName {
  TOTAL_RETURN = "Total Return",
  ANNUALIZED_RETURN = "Annualized Return",
  VOL = "Annualized Volatility",
  AVG_DD = "Average Drawdown",
  MAX_DD = "Max Drawdown",
  SORTINO = "Annualized Sortino",
  SHARPE = "Annualized Sharpe",
  WORST_DAY = "Worst Day",
  BEST_DAY = "Best Day",
  WORST_MONTH = "Worst Month",
  BEST_MONTH = "Best Month",
  WORST_YEAR = "Worst Year",
  BEST_YEAR = "Best Year",
}

export enum MetricType {
  RETURN = "Return",
  VOL = "Volatility",
  AVG_DD = "Average Drawdown",
  MAX_DD = "Max Drawdown",
  SORTINO = "Sortino Ratio",
  SHARPE = "Sharpe Ratio",
  BEST_WROST = "Best Worst",
}

export enum Format {
  PCT = "pct",
  DEC = "dec",
}

export interface BaseMetric {
  metric: MetricType;
  label: MetricName;
  format?: Format;
  decimals?: number;
  periodicity: Periodicity;
}

export interface ExcessReturnMixin extends BaseMetric {
  use_excess_returns: string[];
}

export interface Annualizable extends BaseMetric {
  annualized: boolean;
}

export enum BestWorstPeriod {
  DAY = "D",
  MONTH = "ME",
  YEAR = "YE",
}

export interface BestWorst extends BaseMetric {
  period: BestWorstPeriod;
  best: boolean;
}

export type PerfMetric =
  | BaseMetric
  | ExcessReturnMixin
  | Annualizable
  | BestWorst;

export const defaultMetrics: Record<MetricName, PerfMetric> = {
  [MetricName.TOTAL_RETURN]: {
    metric: MetricType.RETURN,
    periodicity: Periodicity.DAYS,
    label: MetricName.TOTAL_RETURN,
    annualized: false,
  },
  [MetricName.ANNUALIZED_RETURN]: {
    metric: MetricType.RETURN,
    periodicity: Periodicity.DAYS,
    label: MetricName.ANNUALIZED_RETURN,
    annualized: true,
  },
  [MetricName.VOL]: {
    metric: MetricType.VOL,

    periodicity: Periodicity.DAYS,
    label: MetricName.VOL,
    annualized: true,
  },
  [MetricName.AVG_DD]: {
    metric: MetricType.AVG_DD,
    periodicity: Periodicity.DAYS,
    label: MetricName.AVG_DD,
  },
  [MetricName.MAX_DD]: {
    metric: MetricType.MAX_DD,
    periodicity: Periodicity.DAYS,
    label: MetricName.MAX_DD,
  },
  [MetricName.SORTINO]: {
    metric: MetricType.SORTINO,
    periodicity: Periodicity.DAYS,
    use_excess_returns: [],
    label: MetricName.SORTINO,
  },
  [MetricName.SHARPE]: {
    metric: MetricType.SHARPE,
    periodicity: Periodicity.DAYS,
    use_excess_returns: [],
    label: MetricName.SHARPE,
  },
  [MetricName.BEST_DAY]: {
    metric: MetricType.BEST_WROST,
    periodicity: Periodicity.DAYS,
    label: MetricName.BEST_DAY,
  },
  [MetricName.WORST_DAY]: {
    metric: MetricType.BEST_WROST,
    periodicity: Periodicity.DAYS,
    label: MetricName.WORST_DAY,
    period: BestWorstPeriod.DAY,
    best: false,
  },
  [MetricName.BEST_MONTH]: {
    metric: MetricType.BEST_WROST,
    periodicity: Periodicity.DAYS,
    label: MetricName.BEST_MONTH,
    period: BestWorstPeriod.MONTH,
    best: true,
  },
  [MetricName.WORST_MONTH]: {
    metric: MetricType.BEST_WROST,
    periodicity: Periodicity.DAYS,
    label: MetricName.WORST_MONTH,
    period: BestWorstPeriod.MONTH,
    best: false,
  },
  [MetricName.BEST_YEAR]: {
    metric: MetricType.BEST_WROST,
    periodicity: Periodicity.DAYS,
    label: MetricName.BEST_YEAR,
    period: BestWorstPeriod.YEAR,
    best: true,
  },
  [MetricName.WORST_YEAR]: {
    metric: MetricType.BEST_WROST,
    periodicity: Periodicity.DAYS,
    label: MetricName.WORST_YEAR,
    period: BestWorstPeriod.YEAR,
    best: false,
  },
};

export interface PerformanceTableRequest {
  plot_type: "perf_table";
  settings: PerformanceTableSettings;
  metrics: PerfMetric[];
  use_excess_returns: string[];
  data_config: DataConfig;
}

const defaultPerformanceTableSettings: PerformanceTableSettings = {
  font_size: 12,
  alternate_row_color: "#f2f2f2",
  cell_padding: 5,
  first_column_align: "left",
  other_columns_align: "right",
};

export const defaultPerformanceTableRequest: PerformanceTableRequest = {
  plot_type: "perf_table",
  settings: defaultPerformanceTableSettings,
  metrics: [
    defaultMetrics[MetricName.ANNUALIZED_RETURN],
    defaultMetrics[MetricName.TOTAL_RETURN],
    defaultMetrics[MetricName.VOL],
    defaultMetrics[MetricName.SHARPE],
    defaultMetrics[MetricName.MAX_DD],
  ],
  use_excess_returns: [],
  data_config: defaultDataConfig,
};
