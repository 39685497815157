import {
  LinePlotRequest,
  CorrelPlotRequest,
  BarPlotRequest,
  PlotRequest,
  PlotType,
} from "./plot_request_interfaces";
import {
  defaultLinePlotSettings,
  defaultCorrelPlotSettings,
  defaultBarPlotSettings,
} from "../plot_settings/default_plot_settings";
import { resolveTheme } from "../plot_theme/available_themes";
import { defaultDataConfig } from "../data_config/default_data_config";
import { TransformName } from "../transform";
import {
  defaultTransformValues,
  transformSpecificSettings,
} from "../transformDefaults";
import { Periodicity } from "../data_config/data_config_interfaces";

const DEFAULT_LINE_TRANSFORM: TransformName =
  TransformName.INVESTMENT_PERFORMANCE;

export const defaultLinePlotRequest: LinePlotRequest = {
  plot_type: PlotType.LinePlot,
  settings: {
    ...defaultLinePlotSettings,
    ...transformSpecificSettings[PlotType.LinePlot][DEFAULT_LINE_TRANSFORM],
  },
  theme: resolveTheme,
  data_config: {
    ...defaultDataConfig,
    transform: defaultTransformValues[DEFAULT_LINE_TRANSFORM],
  },
};

const DEFAULT_CORREL_TRANSFORM: TransformName = TransformName.RETURNS;

export const defaultCorrelPlotRequest: CorrelPlotRequest = {
  plot_type: PlotType.CorrelPlot,
  settings: {
    ...defaultCorrelPlotSettings,
    ...transformSpecificSettings[PlotType.CorrelPlot][DEFAULT_CORREL_TRANSFORM],
  },
  theme: resolveTheme,
  data_config: {
    ...defaultDataConfig,
    transform: defaultTransformValues[DEFAULT_CORREL_TRANSFORM],
    periodicity: Periodicity.DAYS,
  },
};

const DEFAULT_BAR_TRANSFORM: TransformName = TransformName.RETURNS;

export const defaultBarPlotRequest: BarPlotRequest = {
  plot_type: PlotType.BarPlot,
  settings: {
    ...defaultBarPlotSettings,
    ...transformSpecificSettings[PlotType.BarPlot][DEFAULT_BAR_TRANSFORM],
  },
  theme: resolveTheme,
  data_config: {
    ...defaultDataConfig,
    transform: defaultTransformValues[DEFAULT_BAR_TRANSFORM],
    periodicity: Periodicity.YEARS,
  },
};

export const DEFAULT_PLOT_REQUESTS: Record<PlotType, PlotRequest> = {
  line_plot: defaultLinePlotRequest,
  bar_plot: defaultBarPlotRequest,
  correl_plot: defaultCorrelPlotRequest,
};

export const STARTUP_PLOT_TYPE = defaultLinePlotRequest;
