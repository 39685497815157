import React from "react";
import {
  Box,
  Typography,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import {
  RAMTextField,
  RAMFormControl,
} from "../../../../../../resources/CustomInputs";
import {
  InvestmentPerformance,
  RollingCorrel,
  RollingRet,
  RollingVol,
  TransformName,
  TransformType,
} from "../../../../types/transform";
import { usePlotContext } from "../../../../contexts/PlotContext";
import {
  defaultTransformValues,
  transformSpecificSettings,
} from "../../../../types/transformDefaults";
import { Periodicity } from "../../../../types/data_config/data_config_interfaces";
import { defaultPlotSettings } from "../../../../types/plot_settings/default_plot_settings";

const TransformPanel: React.FC = () => {
  const { plot } = usePlotContext();

  // Generic function to handle changes in transform properties
  const handlePropertyChange = (property: string, value: any) => {
    const updatedTransform = {
      ...plot.config.data_config.transform,
      [property]: value,
    };

    plot.update({
      data_config: {
        ...plot.config.data_config,
        transform: updatedTransform,
      },
    });
  };

  const transformFromName = (transformName: TransformName) => {
    let transform: TransformType;
    switch (transformName) {
      case TransformName.ROLLING_CORREL:
        transform = defaultTransformValues[
          TransformName.ROLLING_CORREL
        ] as RollingCorrel;
        transform.correl_column = plot.config.data_config.portfolios[0].name;
        break;
      default:
        transform = defaultTransformValues[transformName];
        break;
    }
    return transform;
  };

  // Function to filter the transform options by the selected plot
  const getTransformOptions = () => {
    const currentPlotType = plot.config.plot_type;
    const availableTransforms =
      transformSpecificSettings[currentPlotType] || {};
    return Object.values(TransformName).map((name) => ({
      name,
      disabled: !availableTransforms.hasOwnProperty(name),
    }));
  };

  // Function to handle transform selection
  const handleTransformSelection = (transformName: TransformName) => {
    const newTransform = transformFromName(transformName);

    plot.update({
      settings: {
        ...defaultPlotSettings[plot.config.plot_type],
        ...transformSpecificSettings[plot.config.plot_type][transformName],
      },
      data_config: {
        ...plot.config.data_config,
        transform: newTransform,
      },
    });
  };

  // Function to handle periodicity selection
  const handlePeriodicityChange = (newPeriodicity: Periodicity) => {
    plot.update({
      data_config: {
        ...plot.config.data_config,
        periodicity: newPeriodicity,
      },
    });
  };

  return (
    <Box height="100%" width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 0.5, py: 1 }}
      >
        <Typography
          variant="button"
          sx={{
            fontWeight: 800,
            fontSize: 13,
            width: "fit-content",
            color: "#c6c6c6",
          }}
        >
          TRANSFORM SETTINGS
        </Typography>
      </Box>

      <Divider />

      <Box sx={{ p: 2 }}>
        <RAMFormControl fullWidth size="small">
          <InputLabel id="transform-select-label">
            Select Periodicity
          </InputLabel>
          <Select
            labelId="periodicity-select"
            value={plot.config.data_config.periodicity}
            label="Select Periodicity"
            onChange={(e) =>
              handlePeriodicityChange(e.target.value as Periodicity)
            }
          >
            {Object.values(Periodicity).map((period) => (
              <MenuItem key={period} value={period}>
                {period}
              </MenuItem>
            ))}
          </Select>
        </RAMFormControl>
      </Box>

      <Divider />

      <Box sx={{ p: 2 }}>
        <RAMFormControl fullWidth size="small">
          <InputLabel id="transform-select-label">
            Select Transform Type
          </InputLabel>
          <Select
            label="Select Transform Type"
            value={plot.config.data_config.transform.transform}
            onChange={(e) =>
              handleTransformSelection(e.target.value as TransformName)
            }
          >
            {getTransformOptions().map(({ name, disabled }) => (
              <MenuItem key={name} value={name} disabled={disabled}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </RAMFormControl>
      </Box>

      <Divider />

      {plot.config.data_config.transform.transform ===
        TransformName.INVESTMENT_PERFORMANCE && (
        <Box sx={{ p: 2 }}>
          <RAMTextField
            fullWidth
            label="Start Value"
            type="number"
            defaultValue={
              (plot.config.data_config.transform as InvestmentPerformance)
                .start_val
            }
            onBlur={(e) =>
              handlePropertyChange("start_val", Number(e.target.value))
            }
            size="small"
          />
        </Box>
      )}

      {(plot.config.data_config.transform.transform ===
        TransformName.ROLLING_VOL ||
        plot.config.data_config.transform.transform ===
          TransformName.ROLLING_RET ||
        plot.config.data_config.transform.transform ===
          TransformName.ROLLING_CORREL) && (
        <Box sx={{ p: 2 }}>
          <RAMTextField
            fullWidth
            label="Window"
            type="number"
            defaultValue={
              (
                plot.config.data_config.transform as
                  | RollingVol
                  | RollingRet
                  | RollingCorrel
              ).window
            }
            onBlur={(e) =>
              handlePropertyChange("window", Number(e.target.value))
            }
            size="small"
          />
        </Box>
      )}

      <Divider />

      {plot.config.data_config.transform.transform ===
        TransformName.ROLLING_CORREL && (
        <>
          <Box sx={{ p: 2 }}>
            <InputLabel
              id="correlator-select-label"
              sx={{ fontSize: "0.8rem", color: "#c6c6c6", mb: 1 }}
            >
              Select Correlator
            </InputLabel>
            <RadioGroup
              value={
                (plot.config.data_config.transform as RollingCorrel)
                  .correl_column
              }
              onChange={(e) =>
                handlePropertyChange("correl_column", e.target.value)
              }
            >
              {plot.config.data_config.portfolios.map((portfolio) => (
                <FormControlLabel
                  key={portfolio.name}
                  value={portfolio.name}
                  control={<Radio size="small" sx={{ color: "#c6c6c6" }} />}
                  label={portfolio.name}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "0.8rem",
                      color: "#c6c6c6",
                    },
                    ml: 1,
                  }}
                />
              ))}
            </RadioGroup>
          </Box>
          <Divider />
        </>
      )}
    </Box>
  );
};

export default TransformPanel;
